<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'>
      <div>
        <el-descriptions title='钉钉事件通知详情'>
          <el-descriptions-item label='事件类型'>{{ formData.event_type }}</el-descriptions-item>
          <el-descriptions-item label='接收时间'>{{ formData.rec_at }}</el-descriptions-item>
          <el-descriptions-item label='处理时间'>{{ formData.deal_at }}</el-descriptions-item>
          <el-descriptions-item label='处理状态'>
            <el-tag size='small'>{{ dealStatusMap[formData.deal_status] }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label='备注'>{{ formData.remark }}</el-descriptions-item>
        </el-descriptions>
        <div>
          <b>通知详情</b>
          <el-input :autosize='{ minRows: 20, maxRows: 30}' type='textarea'
                    :value='notifyMsg'></el-input>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'ViewDetail',
  computed: {
    dialogTitle() {
      return `详情`
    },
    notifyMsg() {
      if (this.formData && this.formData.notify_msg)
        return JSON.stringify(JSON.parse(this.formData.notify_msg), null, 4)
      else
        return JSON.stringify({})
      // return this.formData.notify_msg || {}
    }
  },
  props: {
    formData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loadingCommit: false,
      // formData: { lan_ip: null, real_ip: null, source: null, status: 'Y', provider_name: '' },
      statusOption: [
        { label: '有效', value: 'Y' },
        { label: '无效', value: 'N' }
      ],
      dealStatusMap: { 1: '待处理', 2: '处理中', 3: '成功', 4: '失败' },
    }
  },
  methods: {
    onOpen() {
      this.loadingCommit = false

    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    }

  }
}
</script>

<style scoped>

</style>
