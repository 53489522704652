<template>
  <page-header-layout>
    <div class='main-page-content'>
      <div class='table-search'>
        <el-form :model='searchCondition' inline size='mini'>
          <el-form-item label='事件类型'>
            <!--            <el-input  v-model='searchCondition.event_type' clearable></el-input>-->
            <el-select placeholder='事件类型' filterable allow-create v-model='searchCondition.event_type' clearable>
              <el-option :label='label' :value='label' v-for='(label,index) in eventTypeArr' :key='index'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label='处理状态'>
            <el-select placeholder='处理状态' v-model='searchCondition.deal_status' clearable>
              <el-option :label='label' :value='value' v-for='(label,value) in dealStatusMap' :key='value'>
                <span style='float: left'>{{ label }} - {{ value }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label='创建时间'>
            <el-date-picker
              v-model='searchCondition.date_range'
              type='daterange'
              align='right'
              unlink-panels
              range-separator='至'
              start-placeholder='开始日期'
              end-placeholder='结束日期'
              value-format='yyyy-MM-dd'
              :picker-options='pickerOptions'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' icon='el-icon-search' @click='getList'>搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class='default-table'>
        <!--    渲染表格-->
        <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                 :loading='loadingStatus' border>
          <el-table-column label='操作' width='100' align='center'>
            <template slot-scope='{row}'>
              <el-button type='text' icon='el-icon-view' @click='showDetail(row)'>详情</el-button>
            </template>
          </el-table-column>
        </m-table>
        <!--    渲染分页-->
        <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                      @pagination='getList' />
      </div>
    </div>
    <!--    查看详情-->
    <ViewDetail :visible.sync='dialogVisible' :form-data='currRow' />
  </page-header-layout>

</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ViewDetail from '@/pages/ding_talk/ViewDetail'

export default {
  name: 'DingTalkEventList',
  components: { ViewDetail, PageHeaderLayout },
  data() {
    return {
      loadingStatus: false,
      searchCondition: { deal_status: null, event_type: null, date_range: [] },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      columns: [
        {
          title: '事件类型',
          value: 'event_type',
          width: 120
        },
        {
          title: '接收时间',
          value: 'rec_at',
          width: 120
        },
        {
          title: '消息内容',
          value: 'notify_msg',
          width: 300
        },
        {
          title: '处理状态',
          value: 'deal_status_alias',
          width: 100
        },
        {
          title: '备注',
          value: 'remark',
          width: 300
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120
        },
        {
          title: '修改时间',
          value: 'updated_at',
          width: 120
        }
      ],
      dataList: [],
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      eventTypeArr: ['check_url', 'user_modify_org'],
      dealStatusMap: { 1: '待处理', 2: '处理中', 3: '成功', 4: '失败' },
      currRow: {},
      dialogVisible: false
    }
  },
  methods: {
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.event_type) {
        condition.event_type = this.searchCondition.event_type
      }
      if (this.searchCondition.deal_status) {
        condition.deal_status = this.searchCondition.deal_status
      }
      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }
      return condition
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData)
      let { list, pages } = await this.$api.getDingTalkEventList(searchCondition)
      list.map((item) => {
        item['deal_status_alias'] = this.dealStatusMap[item.deal_status]
      })
      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })


    },
    async loadOptionMap() {
      let { event_types, deal_status } = await this.$api.getDingTalkEventOptionMap()
      this.eventTypeArr = [...event_types]
      this.dealStatusMap = { ...deal_status }
    },
    showDetail(row) {
      this.currRow = { ...row }
      this.dialogVisible = true
    }
  },
  created() {
    this.getList()
    this.loadOptionMap()
  }
}
</script>

<style scoped>

</style>